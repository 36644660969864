import { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// import { MasonryPhotoAlbum } from "react-photo-album";
// import "react-photo-album/masonry.css";
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./gallery.css";
import { IMAGES_API_URL } from "commons/constants";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";

const { galleries } = require("data/image_manifests/gallery_image_manifest");

export default function GalleryPage({ compact }) {
  // const [searchParams] = useSearchParams();
  const [index, setIndex] = useState(-1);
  const [page, setPage] = useState(1);
  var pageCount = 1,
    pageBase = (page - 1) * 10,
    photosPerPage = 10;
  const activeGallery = useLocation().pathname.split("/").at(-1);
  var photos = [];

  function populatePhotos(cat) {
    pageCount = Math.ceil(cat.length / 10);
    photos = cat.map((el) => ({
      src: el.src,
      width: el.width,
      height: el.height,
      alt: el.alt,
    }));
  }

  function renderSwitch() {
    switch (activeGallery) {
      case "interior":
        populatePhotos(galleries.interior);
        return (
          <>
            <span>Interior Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>As gorgeous on the inside as it is on the outside.</span>
          </>
        );
      case "exterior":
        populatePhotos(galleries.exterior);
        return (
          <>
            <span>Exterior Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>Design with style. Design with a smile.</span>
          </>
        );
      case "bathroom":
        populatePhotos(galleries.bathroom);
        return (
          <>
            <span>Bathroom Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>Best seat in the house.</span>
          </>
        );
      case "kitchen":
        populatePhotos(galleries.kitchen);
        return (
          <>
            <span>Kitchen Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>Your kitchen, your rules!</span>
          </>
        );
      default:
        return (
          <>
            <span>Gallery</span>
            <div className="g-gallery-info-divider"></div>
            <span>
              Here's a sample of some of our recent projects. Inspired by what
              you see? It's only the beginning!
            </span>
          </>
        );
    }
  }

  function GalleryHeader({ type, compact }) {
    const active = activeGallery === type;

    let imageHeader = "";
    switch (type) {
      case "bathroom":
        imageHeader = galleries.bathroom[0].src;
        break;
      case "exterior":
        imageHeader = galleries.exterior[0].src;
        break;
      case "interior":
        imageHeader = galleries.interior[0].src;
        break;
      case "kitchen":
        imageHeader = galleries.kitchen[0].src;
        break;
      default:
        imageHeader = "misc/placeholder-image.webp";
    }

    return (
      <div
        id={`${type}`}
        className={`g-gallery-category${active ? " active" : ""}`}
      >
        <a href={`/gallery/${type}`}>
          <div className="g-category-overlay">
            <span>{type} Photos</span>
          </div>
          <img
            src={`${IMAGES_API_URL}size=tile&compact=${compact}&height=500&imageId=${imageHeader}`}
            alt="placeholder"
          />
        </a>
      </div>
    );
  }

  return (
    <div className="g-page">
      <div className="g-landing">
        <div className="g-landing-content">
          <div className="g-gallery-selector">
            <GalleryHeader key="bathroom" type="bathroom" compact={compact} />
            <GalleryHeader key="exterior" type="exterior" compact={compact} />
            <GalleryHeader key="interior" type="interior" compact={compact} />
            <GalleryHeader key="kitchen" type="kitchen" compact={compact} />
            <span className="g-gallery-label">
              Select one of the Galleries above to see more!
            </span>
          </div>
        </div>
        <BackgroundImg idOverride={2} compact={compact} />
      </div>
      <div className="g-gallery-info">{renderSwitch()}</div>
      <div className="g-gallery-container">
        {activeGallery !== "gallery" && (
          <>
            <RowsPhotoAlbum
              photos={photos
                .slice(pageBase, pageBase + photosPerPage)
                .map((el) => {
                  return {
                    src:
                      IMAGES_API_URL +
                      "size=tile&compact=" +
                      compact +
                      "&imageId=" +
                      el.src,
                    width: el.width,
                    height: el.height,
                  };
                })}
              targetRowHeight={350}
              rowConstraints={{ maxPhotos: 4 }}
              onClick={({ index }) => setIndex(index + pageBase)}
            />
            <Lightbox
              slides={photos.map((el) => {
                return {
                  src:
                    IMAGES_API_URL +
                    "size=lightbox&compact=" +
                    compact +
                    "&imageId=" +
                    el.src,
                  width: el.width,
                  height: el.height,
                };
              })}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              carousel={{ finite: true }}
              plugins={[Fullscreen, Thumbnails, Zoom]}
            />
          </>
        )}
        <div
          id="page-nav"
          style={{
            paddingTop: "1rem",
            display: "grid",
            gap: "1rem",
          }}
        >
          {pageCount > 1 && (
            <>
              <span
                className={`page-nav-btn ${page > 1 ? "active" : ""}`}
                onClick={() => setPage(page - 1)}
              >
                &lt;
              </span>
              <ul
                id="page-numbers"
                style={{ display: "inline", listStyle: "none" }}
              >
                {Array.from({ length: pageCount }, (_, i) => i + 1).map(
                  (number) => (
                    <li
                      style={{ display: "inline" }}
                      value={number}
                      key={`page-${number}`}
                      className={number === page ? "current-page" : ""}
                      onClick={() => setPage(number)}
                    >
                      {number}
                    </li>
                  )
                )}
              </ul>
              <span
                className={`page-nav-btn ${page < pageCount ? "active" : ""}`}
                onClick={() => setPage(page + 1)}
              >
                &gt;
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import HomePage from "./pages/home/HomePage";
import AboutUsPage from "./pages/about-us/AboutUsPage";
import AvailableHomesPage from "./pages/available-homes/AvailableHomesPage";
import ContactUsPage from "./pages/contact/ContactUsPage";
import OurProcessPage from "./pages/our-process/OurProcessPage";
import TestimonialsPage from "./pages/testimonials/TestimonialsPage";
import WhereWeBuildPage from "./pages/where-we-build/WhereWeBuildPage";
import FloorplansPage from "./pages/floorplans/FloorplansPage";

import GalleryPage from "./pages/gallery/GalleryPage";

import ErrorPage from "./pages/error/ErrorPage";

import "App.css";
import "commons/variables.css";
import { compactViewThreshold, mobileNavThreshold } from "commons/constants";

export default function App() {
  const [pos, setPos] = useState(`${100 - window.scrollY}px`);
  const [compactView, setCompactView] = useState(
    window.innerWidth < compactViewThreshold
  );
  const [compactNav, setCompactNav] = useState(
    window.innerWidth < mobileNavThreshold
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 100) {
        setPos(`${100 - window.scrollY}px`);
      } else if (pos !== 0) {
        setPos("0px");
      }
    };
    const handleResize = () => {
      setCompactView(window.innerWidth < compactViewThreshold);
      setCompactNav(window.innerWidth < mobileNavThreshold);
      handleScroll();
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pos]);

  //Effect to control header size
  // function headerSizeHandler() {
  //   if (window.scrollY <= 100) {
  //     setPos(`${100 - window.scrollY}px`);
  //   } else if (pos !== 0) {
  //     setPos("0px");
  //   }
  // }

  return (
    <div className="App" style={{ backgroundColor: "black" }}>
      <Header pos={pos} compact={compactNav} />
      {/* Handle Content Routing Here */}
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage compact={compactView} />} />
          <Route
            path="about-us"
            element={<AboutUsPage compact={compactView} title="About Us" />}
          />
          <Route
            path="available-homes"
            element={
              <AvailableHomesPage
                compact={compactView}
                title="Available Homes"
              />
            }
          />
          <Route
            path="contact"
            element={<ContactUsPage compact={compactView} title="Contact Us" />}
          />
          <Route
            path="gallery/"
            element={<GalleryPage compact={compactView} title="Gallery" />}
          >
            <Route
              path="interior"
              element={
                <GalleryPage compact={compactView} title="Gallery - Interior" />
              }
            />
            <Route
              path="exterior"
              element={
                <GalleryPage compact={compactView} title="Gallery - Exterior" />
              }
            />
            <Route
              path="bathroom"
              element={
                <GalleryPage
                  compact={compactView}
                  title="Gallery - Bathrooms"
                />
              }
            />
            <Route
              path="kitchen"
              element={
                <GalleryPage compact={compactView} title="Gallery - Kitchens" />
              }
            />
          </Route>
          <Route
            path="our-process"
            element={
              <OurProcessPage compact={compactView} title="Our Process" />
            }
          />
          <Route
            path="testimonials"
            element={
              <TestimonialsPage compact={compactView} title="Testimonials" />
            }
          />
          <Route
            path="where-we-build"
            element={
              <WhereWeBuildPage compact={compactView} title="Where We Build" />
            }
          />
          <Route
            path="floorplans"
            element={
              <FloorplansPage compact={compactView} title="Floorplans" />
            }
          />
          <Route
            path="*"
            element={
              <ErrorPage
                compact={compactView}
                errorObj={{ type: "404", msg: "Error 404 - Page Not Found" }}
              />
            }
          />
        </Routes>
      </BrowserRouter>

      <Footer compact={compactView} />
    </div>
  );
}

// export default App;
